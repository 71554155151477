import React, { useEffect, useState } from 'react'
import { Translate } from '../../language';

import Button from '../../utils/Components/Button/Button';
import Input from '../../utils/Components/Input/Input';
import { formatPrice } from '../../utils/functions';
import { Modal } from 'react-bootstrap';

import "./ShopingBag.css"
const PaymentSummary = ({
    isLoadingPointsToRedeem,
    type,
    deliveryAddress,
    setDeliveryAddress,
    deliveryEntityId,
    setDeliveryEntityId,
    pointsRedem,
    setPointsRedem,
    pointsToRedeem,
    listOfReportsLimit,
    subTotal,
    total,
    pay,
    typeUser,
    disscount,
    isPoints,
    totalPoints,
    typeOfDelivery,
    setTypeOfDelivery,
    wareHausesWinery = [],
    typeOfPay,
    setTypeOfPay,
    country, setCountry, state, setState, city, setCity, location, setLocation, phone, setPhone,
}) => {

    const [isEnoughEcopoints, setIsEnoughEcopoints] = useState(false);
    const [showModal, setShowModal] = useState(false);


    const payment_lock = () => {
        if (subTotal < 150000) {
            setShowModal(true);
        } else {
            pay();
        }
    };

    const handleClose = () => setShowModal(false);


    return (
        <div className="payment-summary" >
            <div className="payment-summary-tittle">
                <div className=" text-center text-bold color-purple-dark">Resumen de tu orden</div>
            </div>
            <hr />
            <div className="text-center">
                <div className='container-width margin-center'>
                    <div className='color-purple-dark'>Datos de envío</div>


                    {
                        typeOfDelivery == "home" && (
                            <div className='mt-2'>
                                <Input
                                    center={true}
                                    value={country}
                                    placeholder='País'
                                    onChange={e => setCountry(e.target.value)}
                                />
                                <Input
                                    center={true}
                                    value={state}
                                    placeholder='Departamento'
                                    onChange={e => setState(e.target.value)}
                                />
                                <Input
                                    center={true}
                                    value={city}
                                    placeholder='Ciudad'
                                    onChange={e => setCity(e.target.value)}
                                />
                                <Input
                                    center={true}
                                    value={location}
                                    onChange={e => setLocation(e.target.value)}
                                    placeholder='Barrio'
                                />
                                <Input
                                    center={true}
                                    value={deliveryAddress}
                                    onChange={e => setDeliveryAddress(e.target.value)}
                                    placeholder='Dirección de envío'
                                />
                                <Input
                                    center={true}
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                    placeholder='Teléfono'
                                />
                            </div>
                        )

                    }

                </div>


                {
                    <div>
                        <span className='text-color-green'>Valor total al pagar: </span> {formatPrice(subTotal)}
                    </div>
                }


                <div>
                    <Button
                        disabled={
                            deliveryAddress.lenght <= 5
                        }
                        onClick={payment_lock}
                        text='Finalizar compra'
                    />
                </div>

                <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Aviso</Modal.Title>
                </Modal.Header>
                <Modal.Body>Para aprobar tu compra el pedido debe ser mayor a $ 150.000 COP</Modal.Body>
            </Modal>
            </div>
        </div>
    )
}

export default PaymentSummary
