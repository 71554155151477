import React from 'react'
import { Card } from 'react-bootstrap'
import { Button } from '../Button/Button'
import '../Card/style.css'
import { useNavigate } from "react-router-dom";

export const NCard = ({texto,img,title,id}) => {
    const history = useNavigate()
    return (
        <Card style={{ width: '18rem' }} className='card-style'>
            <div className='img-container'>
                <Card.Img variant="top" src={img}className='product-img-size img-card-news' />
            </div>        
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                   {texto}
                </Card.Text>
                <div className='btn-container'>
                <Button onClick={()=>{history('/job-detail/' + id)}} texto={'Ver más'}></Button>
                </div>                
            </Card.Body>
        </Card>
    )
}

export default NCard