import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers";

const estadoInicial = {};

const store = configureStore({
    reducer:appReducer,
    middleware:(getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
    estadoInicial
})

export default store

