import React from 'react'
import '../Button/style.css'
export const Button = ({texto,icon,onClick,style,estilo,refButton}) => {
    return (
        <div style={style} onClick={onClick} ref={refButton}>
            <div className={`${"pill-btn"}`} style={estilo}>{texto}
            <img src={icon} className='icon-btn-size'>{/* icon == undefined ? '': icon */}</img>
            </div>
        </div>
    )
}
