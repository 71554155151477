import React, { useState, useEffect } from 'react'
import { Translate } from '../../language';
import Button from '../../utils/Components/Button/Button'
import TermsAndConditions from './TermsAndConditions';
import { CloseIcon } from '../../assets/img/icons';
import './ModalTermsAndConditions.css';

const ModalTermsAndConditions = ({ displayModal, closeModal, isChecked }) => {


    const divStyle = {
        display: displayModal ? 'block' : 'none'
    };

    const close = (e) => {
        e.stopPropagation()
        closeModal(e)
    }

    return (
        <div
            className={"modal-terms"}
            onClick={close}
            style={divStyle} >
            <div
                className={`modal-terms-content`}
                onClick={e => e.stopPropagation()} >
                <div onClick={(e) => {
                    close(e)
                }} className="close-modal-terms">
                    <CloseIcon className="close-icon-terms" />
                </div>
                <div className="scroll-menu content-data-terms">
                    <TermsAndConditions />
                </div>
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        isChecked()
                        close(e)
                    }}
                    text={Translate('accept', 'es')}
                    classNameContent={'button-accept-termns'}
                />
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        close(e)
                    }}
                    text={Translate('reject', 'es')}
                    theme={'white'}
                    classNameContent={'button-accept-termns reject-button'}
                />
            </div>
        </div>
    );
}
export default ModalTermsAndConditions;