import inMemoryJwt from '../../auth/inMemoryJwt';
import { urlApi } from "../../config/GlobalConstant";

export const getOilTotal = () => async (dispatch) => {

    await fetch(`${urlApi}static-routes/ecoideaspage/historyoilmanagement/get-total-consolidated`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      withCredentials: true,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((respJson) => {
        dispatch({
          type: 'GET_TOTAL_OIL',
          payload: respJson.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

