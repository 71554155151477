import React, { useEffect } from 'react'
import { Carousel } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAds, getAdsByType } from '../../redux/actions/BannerActions'



export const NCarousel = ({ slide1 }) => {
  const dispatch = useDispatch()
  const { adsNewFeatureLists } = useSelector((state) => state.adsList)
  useEffect(() => {
    dispatch(getAdsByType("newFeature"))

    return () => {

    }
  }, [])

  return (
    <div className="carousel-container">
    <Carousel variant='dark' >
        {adsNewFeatureLists.map(notice => (
          <Carousel.Item className='' style={{ height:"500px"}}>
              
              <img            
                className="mx-auto d-block  object-fit-contain"
                src={notice.image.url.medium}
                alt=""
              />

              <Carousel.Caption className='bg-light  rounded' style={{
                "--bs-bg-opacity":".7",
                top:"15rem",
                bottom:"1rem",
                left:"30%",
                right:"30%"
              }}>
                
                <h3 className='' style={{width:"fit"}}>{notice.name}</h3>
                <p>{notice.description}</p>
                <a href={notice.link} target='blank'> Ver más</a>
              </Carousel.Caption>

            </Carousel.Item>
          ))
        }


      </Carousel>
    </div>
  )
}
