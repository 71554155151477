import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../Aside/style.css'
import factoryIcon from '../../assets/img/factory-icon.svg'
import lightBulb from '../../assets/img/lightbulb-icon.svg'
import likeIcon from '../../assets/img/like-icon.svg'
export const Aside = () => {
    return (
        <Container>
            <Row>
                <Col className='position-col'>
                    <div className='background-rounded'>
                        <img src={lightBulb} alt='' className='size-icons'></img>
                    </div>
                    <h5 className='advantage_title'>INNOVACIÓN</h5>
                    <p3>Diseñamos y producimos soluciones conscientes de cosmética e higiene del hogar biodegradables, manteniendo una estrecha relación con la investigación científica, aplicación del conocimiento y los avances tecnológicos de la industria.</p3>
                </Col>
                <Col className='position-col'>
                    <div className='background-rounded'>
                        <img src={likeIcon} alt='' className='size-icons'></img>
                    </div>
                    <h5 className='advantage_title'>CALIDAD</h5>
                    <p3>Estamos comprometidos contigo y el cuidado del planeta, por eso, cumplimos con altos estándares de calidad y trabajamos permanentemente en mejorar nuestros procesos, productos y la forma en que llegamos a tu hogar.</p3>
                </Col>
                <Col className='position-col'>
                    <div className='background-rounded'>
                        <img src={factoryIcon} alt='' className='size-icons'></img>
                    </div>
                    <h5 className='advantage_title'>SOSTENIBILIDAD</h5>
                    <p3>Buscamos satisfacer las necesidades del presente sin comprometer la capacidad de las futuras generaciones, garantizando el equilibrio entre el crecimiento económico, el cuidado del medioambiente y el desarrollo social.</p3>
                </Col>
            </Row>
        </Container>
    )
}
export default Aside