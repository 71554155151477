import {urlApi} from '../config/GlobalConstant'

const inMemoryJWTManager = () => {
    let inMemoryJWT = null;
    let isRefreshing = null;
    let logoutEventName = 'ra-logout';
    let refreshEndpoint = `${urlApi}api/kento/get-new`;
    let refreshTimeOutId;

    const setLogoutEventName = name => logoutEventName = name;
    const setRefreshTokenEndpoint = endpoint => refreshEndpoint = endpoint;

    // This countdown feature is used to renew the JWT before it's no longer valid
    // in a way that is transparent to the user.
    const refreshToken = (delay) => {
        refreshTimeOutId = window.setTimeout(
            console.log(),
            delay * 1000 - 5000
        ); // Validity period of the token in seconds, minus 5 seconds
    };

    const abordRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const waitForTokenRefresh = () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }
        return isRefreshing.then(() => {
            isRefreshing = null;
            return true;
        });
    }

    const getRefreshedToken = () => {
        const request = new Request(refreshEndpoint, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',

            },
            body: JSON.stringify({
                id: localStorage.getItem('idUser')
            })
        });

        isRefreshing = fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    ereaseToken();
                   
                    return { token: null };
                }
                return response.json();
            })
            .then(({ data,error }) => {
              /*   if (data.data.token) {
                    setToken(data.token.token, data.token.expirationTimeToken);
                    return true;
                } */
                /* ereaseToken();
                return false ; */
            });

        return isRefreshing;
    };

    const getToken = () => {
        // return localStorage.getItem('inMemoryJWT')
    //    return inMemoryJWT
    return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7ImlkIjoiNjI4NjZhMDA4M2IzYzllM2NhZWZhZTJjIiwiZW1haWwiOiJjcmlzdGhpYW5vMTk5NEBnbWFpbC5jb20iLCJjdXJyZW50Um9sZSI6InVzZXIifSwiaWF0IjoxNjUyOTc2MTQ2LCJleHAiOjE2ODQ1MzM3NDZ9.hRWlMVsqN7oNqPzw9i2WT_CsBNNI8RQi2EdtI9lubFM'
    };

    const setToken = (token, delay) => {
       // inMemoryJWT = token;
        localStorage.setItem('inMemoryJWT',token)
        //refreshToken(delay);
        return true;
    };

    const ereaseToken = () => {
        inMemoryJWT = null;
        abordRefreshToken();
        document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); }); 
        window.localStorage.setItem(logoutEventName, Date.now());
        return true;
    }

    // This listener will allow to disconnect a session of ra started in another tab
    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            inMemoryJWT = null;
        }
    })

    return {
        ereaseToken,
        getRefreshedToken,
        getToken,
        setLogoutEventName,
        setRefreshTokenEndpoint,
        setToken,
        waitForTokenRefresh,
    }
};

export default inMemoryJWTManager();