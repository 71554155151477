export const isNumeric = (value) => {
    return /^\d+$/.test(value);
}
export const formatPrice = (value) => {
    const formatter = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    return formatter.format(value);
}
export const sumatoryPrices = (products) => {
    let total = 0
    products.map(product => {
        total = total + (product.quantity * product.unit_price)
    })
    return total
}