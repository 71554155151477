import React from 'react'
import Section from '../Section/Section'
import { Button } from '../Button/Button'
import imgEcosistema from '../../assets/img/ecosistema-emprendimiento.svg'
import '../EcoSystem/style.css'
import { Col, Container, Row } from 'react-bootstrap'

export const EcoSystem = () => {
  return (
    <div>
      <Section
        className='section-title'
        text2={' ECOSISTEMA DE EMPRENDIMIENTO'}
        //Agregado debido al cambio solicitado
      />
      <div >
        <img style={{height:'600px', width:'auto'}} src={imgEcosistema} alt='imagen de ecosistemma de emprendimiento' />
      </div>
      <div style={ { textAlign: 'justify' } } className='center-elements'>
        <Section  
          text1={'¿QUE ES?'}  
          className='section-title'
        />
        <Container>
          <Row>
            <Col>
              <p1>Es una comunidad consciente e incluyente que crece orgánicamente para lograr de manera disruptiva y con un modelo de economía colaborativa su desarrollo sostenible, procurando el bienestar de cada uno de sus integrantes y el cuidado del planeta.      
              </p1>
              
            </Col>
          </Row>
        </Container>

      </div>
      <div className='center-elements'>
        <Section text1={'¿CÓMO PERTENECER '} className='section-title' />
        <Container>
          <Row>
            <Col>
              <p> <strong>Requerimientos</strong>
                <ul>
                  <li>•	Residencia con fachada decorable</li>
                  <li>•	Si la inversión es de contado, no requiere consulta favorable en la central de riesgos</li>
                  <li>•	Si desea postularse a alguna de nuestras opciones de crédito, necesita una consulta favorable en la central de riesgos</li>
                  <li>•	Celular y correo electrónico activos</li>
                  <li>•	Ser mayor de edad, estudios de básica primaria (como mínimo) y tener 2 referencias personales</li>
                  <li>•	Ser usuario de redes sociales y tener buenas relaciones interpersonales</li>
                  <li>•	Estar interesado en el medio ambiente y tener consciencia social</li>
                  <li>• ¡Tener disponibilidad de tiempo, ganas de emprender, disciplina y actitud!</li>
                </ul>

                   <strong>Pasos a seguir</strong>
                <ul>     
                  <li>•	Registrarse en la aplicación de Ecoideas</li>
                  <li>•	Nombre, apellido, celular, correo, fecha de nacimiento, cómo nos conociste / cómo llegaste a nosotros</li>
                  <li>•	Anexar copia de la cédula en formato PDF</li>
                  <li>•	Dirección completa del domicilio, ciudad y departamento</li>
                  <li>•	Foto a color reciente</li>
                  <li>•	Referencias personales escritas y verificadas</li>
                  <li>•	Video de 30 segundos sobre por qué quieres ser un ecomaster Biotú</li>
                  <li>•	Realizar una entrevista personal y/o videollamada (en esta se revisará el cumplimiento de los requerimientos previamente mencionados)</li>   
                </ul>  
              </p>
              {/* <div className='items-center'>
              <Button estilo={{ border: '5px solid #80225f;' }} texto={'ATRÁS'} />
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
export default EcoSystem
