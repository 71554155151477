
import inMemoryJwt from '../../auth/inMemoryJwt';
import { urlApi } from '../../config/GlobalConstant';



export const SeeCompleteCategories = () => async dispatch => {
    dispatch({ //Esto se lo envía a combineReducer, el que está en el index
        type: 'IS_LOADING_LIST_COMPLETE_CATEGORY_PRODUCTS'
    });
    await fetch(`${urlApi}static-routes/ecoideaspage/adminproductcategories/get-all`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${inMemoryJwt.getToken()}`
            // 'Content-Type': 'application/x-www-form-urlencoded',

        }, // body data type must match "Content-Type" header    
    })
        .then(response => response.json())
        .then(respJson => {
            dispatch({ //Esto se lo envía a combineReducer, el que está en el index
                type: 'LIST_COMPLETE_CATEGORY_PRODUCTS',
                payload: respJson.data
            });
        })
        .catch(error => {
            console.log(error)
        })

}