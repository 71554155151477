import React from 'react'
import '../Section/style.css'

export const Section = ({ text1, text2,text3, style,btn }) => {
  return (
    <div>
      <div className='section-style' style={style}>
        <h2>{text1}<span>{text2}</span>{text3}</h2>
        <div>{btn}</div>
      </div>
      {/* <Container>
        <Row>
          <Col sm={8}>{text1}<pan>{text2}</pan></Col>
          <Col sm={4}></Col>
        </Row>
      </Container> */}
    </div>
  )
}

export default Section