
import './App.css';
import { NavBar } from './components/NavBar/NavBar';
import { Footer } from './components/Footer/Footer';
import { BrowserRouter,Route,Routes } from "react-router-dom";
import { Home } from './components/Home/Home';
import JoinUs from './components/Ecomaster/JoinUs';
import EcoSystem from './components/EcoSystem/EcoSystem';
import OurPlatforms from './components/OurPlatforms/OurPlatforms';
import WorkWithUs from './components/WorkWithUs/WorkWithUs';
import News from './components/News/News';
import AboutUs from './components/AboutUs/AboutUs';
import ShowCase from './components/MarketPlace/ShowCase';
import { OurHistory } from './components/OurHistory/OurHistory';
 import ShopingBag from './components/MarketPlace/ShopingBag/ShopingBag'
import { Register } from './components/Register/Register';
import OfferJob from './components/OfferJob/OfferJob';
import { VerifyCode } from './components/VerifyCode/VerifyCode';
import { EcoTienda } from './components/EcoTienda/EcoTienda';

import { EcoTiendaDetail } from './components/EcoTienda/EcoTiendaDetail';
import { OurIdentity } from './components/OurIdentity/OurIdentity';
import FloatingButton from './components/Button/FloatingButton';
import FloatingWhatsAppButton from '../src/components/FloatingButton/FloatingWhatsAppButton';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
    <NavBar />
     <Routes>
       <Route path='/' element={<Home/>}/>
       <Route path='join-us' element={<JoinUs/>}/>
       <Route path='market-place' element={<ShowCase/>}/>
       <Route path='ecomasters' element={<EcoTienda/>}/>
       <Route path='ecosistema' element={<EcoSystem/>}/>
       <Route path='our-platforms' element={<OurPlatforms/>}/>
       <Route path='news' element={<News/>}/>
       <Route path='work-with-us' element={<WorkWithUs/>}/>
       <Route path='about-us' element={<AboutUs/>}/>
       <Route path='our-history' element={<OurHistory/>}/>
       <Route path='shoping-bag' element={<ShopingBag/>}/>
       <Route path='create-user' element={<Register/>}/>
       <Route path='job-detail/:id' element={<OfferJob/>}/>
       <Route path='verify-code' element={<VerifyCode/>}/>
       <Route path='ecotienda-detail/:id' element={<EcoTiendaDetail/>}/>
       <Route path='our-identity' element={<OurIdentity/>}/>
     </Routes>
    <FloatingButton/>
    <FloatingWhatsAppButton/>
    <Footer /> 
  </BrowserRouter>
    </div>
  );
}

export default App;
