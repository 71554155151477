import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEcopointsBuy, getEcopointsRedeem } from "../../redux/actions/MarketPlaceActions";
import { getConsolidateProducts,getBestSellerProducts } from "../../redux/actions/TransferActions";
import ShowCaseView from '../../containers/ShowCase/ShowCaseView';
import { getLimitReports } from '../../redux/actions/ReportActions';
import { SeeCompleteCategories } from '../../redux/actions/PCategoriesActions';
import { SeeSubCategoriesById } from '../../redux/actions/SubCategoriesActions';
import ShippingInfo from "../Policies/Shipping_info";



const MarketPlace = () => {

  const { pointsToRedeem,pointsBuy,consolidateProducts,bestSellerProducts,listOfReportsLimit,
    completeCategoriesList,subCategoriesList
  } = useSelector((state) => state.marketPlace);
  //precio de los ecopuntos:

  const [categoryState, setCategoryState] = useState('');
  const [subCategoryState, setSubCategoryState] = useState('');
  const [page, setPage] = useState(0);
  const [skipConsolidateProducts, setSkipConsolidateProducts] = useState(0);
  const [searchState, setSearchState] = useState("");


  const pageSkip = 50;
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(SeeCompleteCategories())
    //dispatch(getEcopointsRedeem())
    dispatch(getLimitReports())

    if (localStorage.getItem("typeProfile") == "franchise") {
      dispatch(getEcopointsBuy(localStorage.getItem("idProfile")))
    }
    return () => {

    }
  }, [])


  useEffect(() => {
   // dispatch(SeeSubCategoriesById(categoryState))
  }, [categoryState]);

  useEffect(() => {
    dispatch(getBestSellerProducts(page))
  
    return () => {
      
    }
  }, [page])
  



  useEffect(() => {
    dispatch(getConsolidateProducts(skipConsolidateProducts, searchState, categoryState,
      subCategoryState));
  }, [categoryState, subCategoryState, searchState,skipConsolidateProducts]);





  return (
    <div>
    <ShowCaseView
      categoryState={categoryState}
      setCategoryState={setCategoryState}
      subCategoryState={subCategoryState}
      setSubCategoryState={setSubCategoryState}
      searchState={searchState}
      setSearchState={setSearchState}
      ecoPrice={listOfReportsLimit?.ecoPrice}
      bestSellerProducts={bestSellerProducts}
      consolidateProducts={consolidateProducts}
      setPage={setPage}
      categories={completeCategoriesList}
      subCategories={subCategoriesList}
      setSkipConsolidateProducts={setSkipConsolidateProducts}
      pageSkip={skipConsolidateProducts}
      pointsBuy={pointsBuy}
      pointsToRedeem={pointsToRedeem} />
    <ShippingInfo/>
    </div>
  )
}

export default MarketPlace
