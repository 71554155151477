import { useEffect, useRef, useState } from "react";

const LazyImage = ({ src, alt,className,onError,onClickPicture }) => {
    const [isVisible, setIsVisible] = useState(false);
    const imageRef = useRef();
  
    useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }else{
            preloadImage()
          }
        });
      });
  
      observer.observe(imageRef.current);
  
      return () => {
        if (imageRef.current) {
          observer.unobserve(imageRef.current);
        }
      };
    }, []);
    const preloadImage = () => {

      const img = new Image();
      img.src = src;
    };
  
    return (
      <img ref={imageRef} alt={alt} className={className} onError={onError}   src={isVisible ? src : ''} onClick={onClickPicture}/>
    );
  };
  
  export default LazyImage;