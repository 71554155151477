const initialJobsState = {
    isLoadingAds: false,
    adsLists: [],
    adsImageLists: [],
    adsBannerLists: [],
    adsPromotionLists: [],
    adsNewLists: [],
    adsNewFeatureLists: [],
}
export default function (state = initialJobsState, action) {
    switch (action.type) {
        case 'LOADING_GET_ADS':
            return { ...state, isLoadingAds: true }
        case 'GET_ADS':
            return { ...state, adsLists: action.payload, isLoadingAds: false }
        case 'GET_ADS_IMAGE':
            return { ...state, adsImageLists: action.payload, isLoadingAds: false }
        case 'GET_ADS_BANNER':
            return { ...state, adsBannerLists: action.payload, isLoadingAds: false }
        case 'GET_ADS_PROMOTION':
            return { ...state, adsPromotionLists: action.payload, isLoadingAds: false }
        case 'GET_ADS_NEW':
            return { ...state, adsNewLists: action.payload, isLoadingAds: false }
        case 'GET_ADS_NEWFEATURE':
            return { ...state, adsNewFeatureLists: action.payload, isLoadingAds: false }
        default:
            return state;
    }
}