import React from 'react'
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import { Container, Nav, NavDropdown, Navbar, Row, Col } from 'react-bootstrap'
import logotipo from './../../assets/img/ecoideas-logotipo.svg'
import logotipoLittle from './../../assets/img/icons/logo_final.svg'
import '../NavBar/style.css'
import STRING_CONSTANTS from '../../language/es';
import { Link } from "react-router-dom";
import '../NavBar/style.css'
import { Button } from '../Button/Button'
import iconShopping from '../../assets/img/icons/shopping_bag.svg'
import { width } from '@fortawesome/free-solid-svg-icons/fa0';
import { Margin } from '@mui/icons-material';

export const NavBar = () => {
  const location = useLocation()
  const history = useNavigate()
  return (
    <>

      <Navbar expand="lg" className='nav-style'>
        <div className='nav-container'>
          <Nav.Link href='/'>
            <picture>
              <source srcset={logotipo} media="(min-width: 1095px)" />
              <img src={logotipoLittle} alt='logotipo de la aplicacion' className='style-logotipo' />
            </picture>
          </Nav.Link>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto grid-nav">
              <Nav.Link href='/market-place' className={`nav-link ${location.pathname == '/market-place' ? "nav-link-active" : ""}`}>
                {STRING_CONSTANTS.PRODUCTS}
              </Nav.Link>
              <Nav.Link href='/ecomasters' className={`links-style nav-link ${location.pathname == '/ecomasters' ? "nav-link-active" : ""}`}>
                {STRING_CONSTANTS.ECOTIENDAS}
              </Nav.Link>
              <Nav.Link href='/ecosistema' className={`nav-link ${location.pathname == '/ecosistema' ? "nav-link-active" : ""}`}>
                {STRING_CONSTANTS.ECOSISTEMA_ECOIDEAS}
              </Nav.Link>
              <Nav.Link href='/join-us' className={`nav-link ${location.pathname == '/join-us' ? "nav-link-active" : ""}`}>
                {STRING_CONSTANTS.JOIN_US}
              </Nav.Link>
              <Nav.Link href='/our-platforms' className={`nav-link ${location.pathname == '/our-platforms' ? "nav-link-active" : ""}`}>
                {STRING_CONSTANTS.OUR_PLATFORMS}
              </Nav.Link>
              <Nav.Link href='/news' className={`nav-link ${location.pathname == '/news' ? "nav-link-active" : ""}`}>
                {STRING_CONSTANTS.NEWS}
              </Nav.Link>
              <Nav.Link href='/work-with-us' className={`nav-link ${location.pathname == '/work-with-us' ? "nav-link-active" : ""}`}>
                {STRING_CONSTANTS.WORK_WITH_US}
              </Nav.Link>
              <Nav.Link href='/about-us' className={`nav-link ${location.pathname == '/about-us' ? "nav-link-active" : ""}`}>
                {STRING_CONSTANTS.ABOUT_US}
              </Nav.Link>
            </Nav>
                  <Col className='position-buttons' style={{padding: '0%'}} >
                    <Button
                      estilo={{
                        border: '0px solid #8C2769',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center' ,
                        width:'120px',
                        Margin: '8px',                        
                      }}
                        texto={'EcoBolsa'}
                        icon={iconShopping}
                        onClick={() => { history('/shoping-bag') }}
                    />
                  </Col>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  )
}
export default Navbar