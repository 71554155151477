const bag = localStorage.getItem("shoppingBag");
const bagTemp = localStorage.getItem("shoppingBagTemp");
const bagPoints = localStorage.getItem("shoppingBagPoints");
const ubication = localStorage.getItem("ubication");

const initialState = {
  bagTemp: bag ? JSON.parse(bagTemp) : [],
  bag: bag ? JSON.parse(bag) : [],
  bagPoints: bagPoints ? JSON.parse(bagPoints) : [],
  purchases: [],
  reasonsfordeletion: [],
  disscounts: [],
  placesPickup: [],
  batchesUser: new Map(),
  qrImg: null,
  pointsToRedeem: null,
  pointsBuy: 0,
  consolidateProducts: [],
  bestSellerProducts: [],
  listOfReportsLimit: null,
  completeCategoriesList: [],
  subCategoriesList: [],
  isLoadingBestSeller: false,
  ubication: ubication ? ubication : null,
  userInfo: null,
  consolidateFeaturedProducts:[]
};

export default function (state = initialState, action) {
  switch (
    action.type //que me llega la cadena type para el switch y el payload en este caso
  ) {
    case "LIST_COMPLETE_CATEGORY_PRODUCTS":
      return { ...state, completeCategoriesList: action.payload };
    case "POINTS_BUY":
      return { ...state, pointsBuy: action.payload };
    case "POINTS_TO_REDEEM":
      return { ...state, pointsToRedeem: action.payload };
    case "LIST_placesPickup":
      return { ...state, placesPickup: action.payload };
    case "CLEAR_DISSCOUNT":
      return { ...state, disscounts: [] };
    case "ADD_DISSCOUNT":
      const disscounts = state.disscounts;
      disscounts.pop();
      disscounts.push(action.payload);
      return { ...state, disscounts: disscounts };
    case "ADD_TO_POINTS_BAG":
      var bagPoints = state.bagPoints;

      if (bagPoints.find((product) => product._id === action.payload._id)) {
        bagPoints.map((product) => {
          if (product._id === action.payload._id) {
            product.quantity = product.quantity + 1;
          }
        });
      } else {
        bagPoints.push({
          ...action.payload,
          quantity: 1,
          unit_price: action.payload.price,
        });
      }
      localStorage.setItem("shoppingBagPoints", JSON.stringify(bagPoints));
      return { ...state, bagPoints };
    case "REMOVE_ITEM_POINTS_BAG":
      var bagPoints = state.bagPoints;
      localStorage.setItem(
        "shoppingBagPoints",
        JSON.stringify(
          bagPoints.filter((product) => product._id !== action.payload._id)
        )
      );
      return {
        ...state,
        bagPoints: bagPoints.filter(
          (product) => product._id !== action.payload._id
        ),
      };

    case "CHANGE_QUANTITY_POINTS_BAG":
      var bagPoints = state.bagPoints;
      bagPoints.map((product) => {
        if (product._id === action.payload.item._id) {
          product.quantity = action.payload.quantity;
        }
      });
      localStorage.setItem("shoppingBagPoints", JSON.stringify(bagPoints));
      return { ...state, bagPoints };

    ///
    case "CLEAR_BAG":
      localStorage.setItem("shoppingBag", JSON.stringify([]));
      return { ...state, bag: [] };
    case "ADD_TO_BAG":
      // Clonar la bolsa para evitar mutar el estado directamente
      var newBag = Array.from(state.bag);

      // Buscar si el producto ya está en la bolsa
      const existingProductIndex = newBag.findIndex(
        (product) => product._id === action.payload._id
      );

      if (existingProductIndex !== -1) {
        // Si el producto ya está en la bolsa, actualizar la cantidad
        newBag = newBag.map((product, index) => {
          if (index === existingProductIndex) {
            return { ...product, quantity: product.quantity + 1 };
          } else {
            return product;
          }
        });
      } else {
        // Si el producto no está en la bolsa, añadirlo con cantidad 1
        newBag.push({
          ...action.payload,
          quantity: 1,
          unit_price: action.payload.price,
        });
      }

      // Guardar la nueva bolsa en el almacenamiento local
      localStorage.setItem("shoppingBag", JSON.stringify(newBag));

      // Devolver un nuevo estado con la nueva bolsa
      return { ...state, bag: newBag };
    case "REMOVE_ITEM":
      var bag = state.bag;
      localStorage.setItem(
        "shoppingBag",
        JSON.stringify(
          bag.filter((product) => product._id !== action.payload._id)
        )
      );
      return {
        ...state,
        bag: bag.filter((product) => product._id !== action.payload._id),
      };

    case "CHANGE_QUANTITY":
      const updatedBag = state.bag.map((product) => {
        if (product._id === action.payload.item._id) {
          return { ...product, quantity: action.payload.quantity };
        } else {
          return product;
        }
      });
      localStorage.setItem("shoppingBag", JSON.stringify(updatedBag));
      return { ...state, bag: updatedBag };
    case "PAY_SUCCESS":
      return { ...state, qrImg: action.payload };
    case "GET_PURCHASES":
      return { ...state, purchases: action.payload };
    case "GET_BATCHES_USER":
      return { ...state, batchesUser: action.payload };
    case "GET_REASONS":
      return { ...state, reasonsfordeletion: action.payload };
    case "GET_CONSOLIDATE_PRODUCTS":
      return { ...state, consolidateProducts: action.payload };
      case "GET_CONSOLIDATE_FETATURED_PRODUCTS":
        return { ...state, consolidateFeaturedProducts: action.payload };
    case "IS_LOADING_BESTSELLER_PRODUCTS":
      return { ...state, isLoadingBestSeller: true };
    case "GET_BESTSELLER_PRODUCTS":
      return { ...state, bestSellerProducts: action.payload };
    case "SEND_TO_PAY":
      localStorage.setItem("ubication", JSON.stringify(action.payload));
      return { ...state, ubication: action.payload };
    case "GET_USER_INFO":
      return { ...state, userInfo: action.payload };
    default:
      return state;
  }
}
