
import React from 'react'
import './Button.css'

const Button = ({
    text = '',
    type = "button",
    theme = '',
    styles = {},
    width = '',
    height = '',
    disabled = false,
    active = false,
    value = '',
    name = '',

    classNameContent,

    onClick = () => { }
}) => {
    const style = {
        width: width,
        height: height,
        ...styles
    }
    return (
        <div className={classNameContent} style={style}>
            <button
                type={type}
                disabled={disabled}
                onClick={onClick}
                className={`button-style  ${active ? 'white-hover' : 'white'} `}
            >
                {text}
            </button>
        </div>
    )
}

export default Button