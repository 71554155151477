import inMemoryJwt from '../../auth/inMemoryJwt';
import { urlApi } from '../../config/GlobalConstant';


export const getConsolidateProducts = (page, textString, categoryId, subcategoryId, limit = 25) => async dispatch => {
    await fetch(`${urlApi}static-routes/ecoideaspage/maininventories/get-consolidate-products`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
         
            // 'Content-Type': 'application/x-www-form-urlencoded',

        }, // body data type must match "Content-Type" header    
        body: JSON.stringify({
            limit,
            skip: page,
            textString,
            categoryId,
            subcategoryId
        })
    })
        .then(response => response.json())
        .then(respJson => {
            if (respJson.success) {
            
                dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
                    type: 'GET_CONSOLIDATE_PRODUCTS',
                    payload: respJson.data
                });
            }
            else {
                dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
                    type: 'GET_CONSOLIDATE_PRODUCTS',
                    payload: []
                });
            }
        }).catch(error => {

        })
}
export const getConsolidateFeaturedProducts = (page,limit = 5) => async dispatch => {
    await fetch(`${urlApi}static-routes/ecoideaspage/maininventories/get-consolidate-products`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
         
            // 'Content-Type': 'application/x-www-form-urlencoded',

        }, // body data type must match "Content-Type" header    
        body: JSON.stringify({
            limit,
            skip: page,
            featured:true
        })
    })
        .then(response => response.json())
        .then(respJson => {
            if (respJson.success) {
            
                dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
                    type: 'GET_CONSOLIDATE_FETATURED_PRODUCTS',
                    payload: respJson.data
                });
            }
            else {
                dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
                    type: 'GET_CONSOLIDATE_FETATURED_PRODUCTS',
                    payload: []
                });
            }
        }).catch(error => {

        })
}
export const getBestSellerProducts = (page, limit = 5) => async dispatch => {
    dispatch({ type: 'IS_LOADING_BESTSELLER_PRODUCTS' });

    await fetch(`${urlApi}static-routes/ecoideaspage/products/get-report-by-type`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${inMemoryJwt.getToken()}`
            // 'Content-Type': 'application/x-www-form-urlencoded',

        }, // body data type must match "Content-Type" header    
        body: JSON.stringify({
            reportType: "topSold",
            limit: 5,
            skip: page
        })
    })
        .then(response => response.json())
        .then(respJson => {
            if (respJson.success) {

                dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
                    type: 'GET_BESTSELLER_PRODUCTS',
                    payload: respJson.data
                });
            }
            else {
                dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
                    type: 'GET_BESTSELLER_PRODUCTS',
                    payload: []
                });
            }
        }).catch(error => {

        })
}
