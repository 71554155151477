import React from 'react'
import { Card } from 'react-bootstrap'
import { Button } from '../Button/Button'
import '../Card/style.css'
import { useNavigate } from "react-router-dom";

export const BCard = ({texto,img,price,logo,name}) => {
    const history = useNavigate()
    return (   
        <Card style={{ width: '18rem' }} className='card-style'>
            { /*
             <div className='icon-star-container'>
                <Card.Img variant='top' src={logo} className='icon-size icon-star-space'/>
            </div> Esta parte fue eliminada debido a que es la estrella de los productos mas vendidos
             */} 
            <div className='product-img-container'>
                <Card.Img variant="top" src={img}className='product-img-size' />
            </div>        
            <Card.Body>
                <Card.Title>{name}</Card.Title>
               {/*} <textarea 
                maxLength={30} 
                style={{border:'none',resize:'none',outline:'none',overflow:'hidden'}}>
                   {texto}
                </textarea>
               */}
                <div className='btn-container'>
                <Button onClick={()=>{history('/market-place')}} texto={'Comprar'}></Button>
                </div>
            </Card.Body>
        </Card>
    )
}

export default BCard