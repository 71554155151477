import React from 'react'
import { Button } from '../Button/Button'
import Section from '../Section/Section'
import iconWeb from '../../assets/img/icon-web.svg'
import iconMovil from '../../assets/img/icon-movil.svg'
import googlePlay from '../../assets/img/google-play-badge.png'
import appleStore from '../../assets/img/apple-store.svg'
import '../OurPlatforms/style.css'
import { Col, Container, Row } from 'react-bootstrap'
import './style.css'
export const OurPlatforms = () => {
  return (
    <div>
      <div className='center-elements'>
        <Section
         
          text2={'PLATAFORMAS'}
        />
        <p style={{ width: '60%', textAlign: 'justify' }}>Ofrecemos plataformas seguras e intuitivas, dedicadas a la comercialización de
          productos biodegradables de alta calidad, fomentamos la creación de comunidad
          para apalancar tu crecimiento</p>
      </div>
      <Container className='space-container'>
        <Row className="justify-content-center">
          <Col xs={12} sm={4} className="d-flex align-items-center justify-content-center">
            <div className='sections text-center'>
              <div className='icon-container-plat'>
                <img src={iconWeb} alt='icono de navegación web' className='icon-size' />
              </div>
              <h4 className='text-secondary text-medium color-black'>Aplicación Web</h4>
            </div>
          </Col>
          <Col sm={8} className="d-none d-sm-block">
            <div style={{ textAlign: 'center' }} className='w-full text-center'>
              <p className='text-start m-4'>Promovemos la conciencia ambiental y la adecuada
                disposición de residuos, brindando información y recursos que fomenten prácticas
                sostenibles para contribuir a la conservación del planeta.
              </p>
              <a href="https://ecoideas.app" className="primary-button" aria-label="Ir a la aplicación web">
                Ir a la aplicación web
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className='space-container'>
        <Row className="justify-content-center">
          <Col xs={12} sm={4} className='d-flex align-items-center justify-content-center'>
            <div className='sections text-center'>
              <div className='icon-container-plat'>
                <img src={iconMovil} alt='icono de teléfono celular' className='icon-size' />
              </div>
              <h4 className='text-secondary text-medium color-black'>Aplicación Móvil</h4>
            </div>
          </Col>
          <Col sm={8} className="d-none d-sm-block">
            <div className='w-full text-center'>
              <p className='text-start m-4'>Facilitamos la conexión entre personas interesadas en el medio ambiente,
                proporcionando un espacio virtual donde puedan compartir conocimientos, ideas e incluso productos.
                Además, podrán participar en iniciativas que promuevan la sostenibilidad, la protección del entorno y la
                creación de nuevos negocios.
              </p>
              <div className='img-store-container m-4 d-none d-sm-flex justify-content-center'>
                <a href='https://play.google.com/store/apps/details?id=com.ecoideas.app&hl=es_419'
                  style={{ textDecoration: 'none' }}
                  target='_blank'
                  aria-label="Descargar desde Google Play"
                >
                  <img className='img-store' src={googlePlay} alt="Google Play Store" />
                </a>
                <a href='https://apps.apple.com/us/app/ecoideas-app/id6475202942'
                  style={{ textDecoration: 'none' }}
                  target='_blank'
                  aria-label="Descargar desde Apple Store"
                >
                  <img className='img-store' src={appleStore} alt="Apple App Store" />
                </a>
              </div>
            </div>
          </Col>
          <div className='d-flex justify-content-center d-sm-none'>
            <a href='https://play.google.com/store/apps/details?id=com.ecoideas.app&hl=es_419'
              style={{ textDecoration: 'none' }}
              target='_blank'
              aria-label="Descargar desde Google Play"
            >
              <img className='img-store' src={googlePlay} alt="Google Play Store" />
            </a>
            <a href='https://apps.apple.com/us/app/ecoideas-app/id6475202942'
              style={{ textDecoration: 'none' }}
              target='_blank'
              aria-label="Descargar desde Apple Store"
            >
              <img className='img-store' src={appleStore} alt="Apple App Store" />
            </a>
          </div>
        </Row>
      </Container>
    </div>
  )
}
export default OurPlatforms