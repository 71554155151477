const initialJobsState = {
    isLoadingGeteEcotienda:false,
    getEcotienda:false,
    ecotiendas:[],
    ecotienda:{},
}
export default function (state = initialJobsState, action) {
    switch (action.type) {
        case 'LOADING_GET_ECOTIENDAS':
            return { ...state, isLoadingGeteEcotienda:true }
        case 'GET_ECOTIENDAS':
            return { ...state, ecotiendas: action.payload,isLoadingGeteEcotienda:false }
        case 'LOADING_GET_ECOTIENDA_BY_ID':
            return { ...state, getEcotienda:true }
        case 'GET_ECOTIENDA_BY_ID':
            return { ...state, ecotienda: action.payload,getEcotienda:false }
        default:
            return state;
    }
}