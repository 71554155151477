import inMemoryJwt from '../../auth/inMemoryJwt';
import { urlApi } from "../../config/GlobalConstant";

export const getJobOffers = () => async (dispatch) => {
    dispatch({
      type: 'LOADING_GET_JOBS_OFFERS',
    });
    await fetch(`${urlApi}static-routes/ecoideaspage/joboffers/get-all`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${inMemoryJwt.getToken()}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => response.json())
      .then((respJson) => {
        dispatch({
          type: 'GET_JOBS_OFFERS',
          payload: respJson.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  export const getJobOfferById = (id) => async (dispatch) => {
    dispatch({
      type: 'LOADING_GET_OFFER_BY_ID',
    });
    await fetch(`${urlApi}static-routes/ecoideaspage/joboffers/get-by-id`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${inMemoryJwt.getToken()}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        jobOfferId : id
    })
    })
      .then((response) => response.json())
      .then((respJson) => {
        dispatch({
          type: 'GET_JOB_OFFER_BY_ID',
          payload: respJson.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };