import { useEffect, useState } from "react";
import useScript from "./useScript";


export default function useMercadoPago() {
    const [mp, setMp] = useState(undefined);

    const { MercadoPago } = useScript(
        "https://sdk.mercadopago.com/js/v2",
        "MercadoPago"
    );

    useEffect(() => {
        if (MercadoPago) {
            setMp( 
                new MercadoPago("APP_USR-d8c1a5ae-71d9-4e52-b6ab-e39a4332041a", {
                locale: 'es-CO'
          }))
        }
    }, [MercadoPago]);

    return mp;
}