import inMemoryJwt from '../../auth/inMemoryJwt';
import { urlApi } from "../../config/GlobalConstant";

export const getEcotiendas = () => async (dispatch) => {
    dispatch({
      type: 'LOADING_GET_ECOTIENDAS',
    });
    await fetch(`${urlApi}static-routes/franchises/get-all`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${inMemoryJwt.getToken()}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => response.json())
      .then((respJson) => {
        dispatch({
          type: 'GET_ECOTIENDAS',
          payload: respJson.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  export const getEcotiendaById = (id) => async (dispatch) => {
    dispatch({
      type: 'LOADING_GET_ECOTIENDA_BY_ID',
    });
    await fetch(`${urlApi}franchises/get-id`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${inMemoryJwt.getToken()}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        franchiseID : id
    })
    })
      .then((response) => response.json())
      .then((respJson) => {
        dispatch({
          type: 'GET_ECOTIENDA_BY_ID',
          payload: respJson.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };