import React from 'react'
import { Card } from 'react-bootstrap'
import { Button } from '../Button/Button'
import '../Card/style.css'

export const NotiCard = ({ texto, img, title, link }) => {

  return (
    <Card style={{ width: '300px', height:'520px' }} className='card-style'>
      <div className='img-container'>
        <Card.Img variant="top" src={img} className='product-img-size-eco img-card-news' />
      </div>
      <Card.Body className="grid-container">
        <Card.Title>{title}</Card.Title>
        <Card.Text >
          {texto}
        </Card.Text>
        <div className='btn-container' >
          <Button  onClick={() => {
            window.open(link, "_blank");
          }} texto={'Ver más'}></Button>
        </div>
      </Card.Body>
    </Card>
  )
}
