
const STRING_CONSTANTS = {
    PRODUCTS:'Productos',
    ECOTIENDAS:'EcoMasters',  //Cambio de ecotiendas a ecomasters
    ECOSISTEMA_ECOIDEAS:'EcoSistema', //cambio de ecoideas sistema ecoideas a ecosistema
    JOIN_US:'EcoTiendas',
    OUR_PLATFORMS:'Plataformas',
    WORK_WITH_US:'Trabaja Con Nosotros',
    ABOUT_US:'Ecoideas',
    NEWS:'Noticias',
    MarketPlace: "MarketPlace",
    myPurchases: 'Mis Compras',
    ecoPoints: "EcoPuntos",
    Ecobolsa: "EcoBolsa",
    BestSeller: "Lo Más Vendido",
    accept: 'Aceptar',
    reject: 'Rechazar'
    
}

export default STRING_CONSTANTS;