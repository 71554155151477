import inMemoryJwt from '../../auth/inMemoryJwt';
import { urlApi } from "../../config/GlobalConstant";

export const getAds = () => async (dispatch) => {
  dispatch({
    type: 'LOADING_GET_ADS',
  });
  await fetch(`${urlApi}static-routes/ecoideaspage/adminadvertising/get-all`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${inMemoryJwt.getToken()}`,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then((response) => response.json())
    .then((respJson) => {
      dispatch({
        type: 'GET_ADS',
        payload: respJson.data,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getAdsByType = (advertisingType) => async (dispatch) => {
  dispatch({
    type: 'LOADING_GET_ADS',
  });
  await fetch(`${urlApi}static-routes/ecoideaspage/adminadvertising/get-by-type`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    withCredentials: true,
    credentials: "include",
    body: JSON.stringify({
      advertisingType
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${inMemoryJwt.getToken()}`,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then((response) => response.json())
    .then((respJson) => {
      if(respJson.success){
        dispatch({
          type: `GET_ADS_${advertisingType.toUpperCase()}`,
          payload: respJson.data,
        });
      }
     
    })
    .catch((error) => {
      console.log(error);
    });
};