import React, { useEffect, useState } from 'react'
import Aside from '../Aside/Aside'
import { Button } from '../Button/Button'
import BCarousel from '../Carousel/BCarousel'
import Section from '../Section/Section'
import iconShopping from '../../assets/img/icons/shopping_bag.svg'
import imgMark from '../../assets/img/our-mark.png'
import biotu from '../../assets/img/biotu.png'
import BCard from '../Card/BCard'
import imgCoil from '../../assets/img/coil-icon.svg'
import { Col, Container, Row } from 'react-bootstrap'
import '../Home/style.css'
import slide1 from '../../assets/img/slide1.svg'
import star from './../../assets/img/icons/star.svg'
import coral from '../../assets/img/icons/coral-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getBestSellerProducts, getConsolidateFeaturedProducts} from '../../redux/actions/TransferActions'
import { useNavigate } from "react-router-dom"
import { getOilTotal } from '../../redux/actions/OildActions'
import ICarrousel from '../Carousel/ICarousel'
import { height } from '@fortawesome/free-solid-svg-icons/fa0'



export const Home = () => {
  const { bestSellerProducts,consolidateFeaturedProducts } = useSelector((state) => state.marketPlace);
  const { totalOil } = useSelector((state) => state.oil);
  console.log('mejores productos', bestSellerProducts);
  const dispatch = useDispatch()
  const [page, setPage] = useState(0);
  useEffect(() => {
    dispatch(getOilTotal())

    return () => {

    }
  }, [])

  useEffect(() => {
    dispatch(getBestSellerProducts(page))
  }, [page])

  useEffect(() => {
    dispatch(getConsolidateFeaturedProducts(0))
  }, [])
  const history = useNavigate()

  return (
    
    <div> 
      
     {/*<BCarousel consolidateFeaturedProducts={consolidateFeaturedProducts} slide1={slide1} /> Carrusel de productos destacados*/}
     <ICarrousel/>           
      <Container>
        <Row>
          <Col>
            <Section
              
              text2={'PRODUCTOS MÁS VENDIDOS'}
            /* btn={<Button estilo={{ border: '1px solid #A7D9B3;' }} texto={'CHATEA CON NOSOTROS'} icon={iconShopping} />} */
            />
          </Col>

         {/* <Col className='position-buttons'> 
           <Button 
                onClick={() => {
                  const phone = "57 313 2903907".replaceAll(" ","")
                  const link = `https://wa.me/${phone}`
                  window.open(link, "_blank")
                  //https://wa.me/573218190490
              }}
          
              estilo={{
              border: '1px solid #80225f;',
              display: 'flex',
              flexDirection: 'row',
              padding: '0%',
              justifyContent: 'center',
              backgroundColor: '#a3d8b4',
              border: '#a3d8b4'
            }} texto={'CHATEA CON NOSOTROS'} icon={coral} />
          </Col> */}
         </Row>
      </Container>

      <div className='cards-container'>
        {bestSellerProducts?.map((element) => (
          <BCard
            key={element._id}
            img={element.image && element.image.url && element.image.url.small}
            name={element.name}
            texto={element.description}
            alt='imagen de ejemplo de productos'
            logo={star}
          />
        ))}

      </div>

      <Section
    
        text2={'AGUA SIN CONTAMINAR'}
    
      />
      <div className='section-coil'>
        <div className='coil-container'>
          <img src={imgCoil} alt='imagen de contador de aceite'  style={{ height: '160px' }}></img>
        </div>
        <div className='coil-values-container'>
      {/*    <h4>Reciclamos {totalOil} litros<span className='font-size-coil'> de aceite</span></h4>*/}
        <h5>Salvamos {totalOil * 1000} litros de agua</h5>
      {/*    <h4>{totalOil * 2.5} toneladas menos<span className='font-size-coil'> de CO2 emitidas</span></h4>*/}
        </div>
      </div>
      
      <Section
        text2={'MARCAS ECOIDEAS'}
      />

      <div className='our-mark-container'>

        <div className='our-mark-container-info' >
        <img  className='our-mark-container-logo' src={biotu} alt='biotu' ></img>
        
        <p>Somos BIOTÚ, una marca ecoamigable, especializada en productos de aseo biodegradables. Somos un equipo de apasionados que entiende que tú, y el planeta, son lo más importante. Desde nuestra fundación, hemos dedicado nuestros esfuerzos en crear productos de la más alta calidad, elaborados de manera sostenible, y a un precio justo. Cada vez que nos eliges, te unes a una comunidad comprometida con el cuidado del ambiente; en cada producto que fabricamos, en cada decisión que tomamos, pensamos en ti. Juntos, hacemos la diferencia.</p>
      
         <Button texto={"Ver mas"} onClick={() => { history('/about-us') }}/>
        </div>

        <img  className='our-mark-container-img' src={imgMark} alt='imagen con descripcion de la marca' ></img>
      </div>
     {/* <Section
        text1={<> SÉ <span>ECOMASTER</span> Y RECIBE GRANDES BENEFICIOS</>}
        style={{ backgroundColor: '#A7D9B3', fontWeight: "bold", color: 'white' }}
      />
      */}
      <Section
    
        text2={'PRINCIPIOS'}
      />
      <Aside />
    </div>
  )
}
export default Home