import React, { useEffect, useRef, useState } from 'react'
import Section from '../Section/Section'
import { Button } from '../Button/Button'
import imgSerEcomaster from '../../assets/img/como-ser-ecotienda.svg'
import { Col, Container, Row  } from 'react-bootstrap'
import '../Ecomaster/style.css'
import { useNavigate } from "react-router-dom";
import { EcotiendaSteps } from '../EcotiendaSteps/EcotiendaSteps';
import ProgressSteps from './ProgressSteps'


export const JoinUs = () => {
  const history = useNavigate()
  const [isOpenSteeps, setIsOpenSteeps] = useState(false)
  const buttonRef = useRef(null);
  useEffect(() => {
    
    if (isOpenSteeps && buttonRef.current) {
      setTimeout(() => {
        buttonRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);

   
    }
    return () => {
      
    }
  }, [isOpenSteeps])
  
  return (
    <div>
      <Section
  
        text2={'¿CÓMO SER ECOTIENDA?'}
      />
      <div>
        <img src={imgSerEcomaster} alt='imagen de como ser ecomaster' />
      </div>
      <Container className='pt-5'>
        <Row>
          <Col>
          <p> <strong>Requerimientos</strong>
          
                <ul>
                  <li>•	Local propio o alquilado con contrato</li>
                  <li>•	Capital de 50’000,000 COP</li>
                  <li>•	Área mínima 50 metros cuadrados</li>
                  <li>•	Contar con un vehículo</li>
                  <li>•	Experiencia comercial</li>
                  <li>•	Estudios de básica primaria como mínimo</li>
                  <li>•	2 referencias personales y 1 bancaria</li>
                  <li>•	Celular y correo electrónico activos</li>
                  <li>•	Usuario de redes sociales y buenas relaciones interpersonales</li>
                  <li>•	¡Tener disponibilidad de tiempo, disciplina y actitud!</li>
                </ul>

                   <strong>Pasos a seguir</strong>
                <ul>     
                  <li>•	Registrarse en la aplicación de Ecoideas</li>
                  <li>•	Nombre, apellido, celular, correo, fecha de nacimiento, cómo nos conociste / cómo llegaste a nosotros</li>
                  <li>•	Anexar copia de la cédula en formato PDF</li>
                  <li>•	Anexar copia del contrato de arrendamiento (si lo hay)</li>
                  <li>•	Dirección completa de local, ciudad y departamento</li>
                  <li>•	Foto a color reciente</li>
                  <li>•	Referencias personales escritas y verificadas</li>
                  <li>•	Realizar una entrevista personal y/o videollamada 
                      (en esta se revisará el cumplimiento de los requerimientos previamente mencionados)</li>
                  <li>•	Video de 30 segundos sobre por qué quieres abrir una Ecotienda Biotú</li>   
                </ul>  
              </p>
          </Col>
        </Row>
      </Container>
    
      <Container>
        <Row>
          <Col className='align-buttons'>
            <Button 
             onClick={() => { 
              setIsOpenSteeps(true)
              if (isOpenSteeps && buttonRef.current) {
                
                buttonRef.current.scrollIntoView({ behavior: 'smooth' });
              }  
             }}
                estilo={{ border: '5px solid black;', width: '270px', fontText:'8px'}}
                texto={'CONSULTA LA GUÍA PASO A PASO'} /></Col>
                <div ref={buttonRef} ></div>
             {
            isOpenSteeps &&
            <ProgressSteps />
           }
        </Row>
       
      </Container>
      <Section />
      
      
    </div>
  )
}
export default JoinUs