import React, { useState } from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import Button from '../../utils/Components/Button/Button'
import ModalTermsAndConditions from '../ModalTerms/ModalTermsAndConditions'
import './style.css'

export const RegisterView = ({ formik }) => {
    const [isShowModalTerms, setIsShowModalTerms] = useState(false)

    return (
        <div>
            <h1 className="register-title">Completa tus Datos para una Compra Segura</h1>
            <Container>
                <Row>
                    <Col>
                    <label htmlFor="name">Nombre</label>
                <input
                    className='input-style'
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                />
                {formik.errors.name&&<div className='input-errors'>{formik.errors.name}</div>}
                <label htmlFor="lastname">Apellido</label>
                <input
                    className='input-style'
                    id="lastname"
                    name="lastname"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.lastname}
                />
               
                <label htmlFor="email">Correo</label>
                <input
                    className='input-style'
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                />
                {formik.errors.email&&<div className='input-errors'>{formik.errors.email}</div>}
                <label htmlFor="birthDate">Fecha de Nacimiento</label>
                <input
                    className='input-style'
                    id="birthDate"
                    name="birthDate"
                    type="date"
                    onChange={formik.handleChange}
                    value={formik.values.birthDate}
                />
                {formik.errors.birthDate&&<div className='input-errors'>{formik.errors.birthDate}</div>}
                <label htmlFor="check">Términos y Condiciones </label>
                <input
                    readOnly={true}
                    type='checkbox'
                    checked={formik.values.isAcceptTerms == true}
                    onClick={()=>{
                        if (formik.values.isAcceptTerms == true) {
                            formik.setFieldValue('isAcceptTerms',false)
                        }else{
                            setIsShowModalTerms(true)
                        }
                    }}
                     />
                    </Col>
                    <Col>
                    
                <label htmlFor="passWord">Contraseña</label>
                <input
                    className='input-style'
                    id="passWord"
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.passWord}
                />
                {formik.errors.passWord&&<div className='input-errors'>{formik.errors.passWord}</div>}
                <label htmlFor="confirmPassWord">Confirmar Contraseña</label>
                <input
                    className='input-style'
                    id="confirmPassWord"
                    name="confirmPassWord"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassWord}
                />
                {formik.errors.confirmPassWord&&<div className='input-errors'>{formik.errors.confirmPassWord}</div>}
                <label htmlFor="phone">Teléfono</label>
                <input
                    className='input-style'
                    id="phone"
                    name="phone"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                />
                {formik.errors.phone&&<div className='input-errors'>{formik.errors.phone}</div>}

                    </Col>
                </Row>
            </Container>
            {isShowModalTerms && <ModalTermsAndConditions
             displayModal={isShowModalTerms}
             closeModal={()=>{setIsShowModalTerms(false)}}
             isChecked={()=>{formik.setFieldValue('isAcceptTerms',true)}}
             />}
          <div className='container-btn-register'>
            <Button
                text='Enviar'
                type='submit'
                onClick={formik.handleSubmit}
                />
          </div>
                
        </div>
    )
}
