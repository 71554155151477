import inMemoryJwt from '../../auth/inMemoryJwt';
import { urlApi } from '../../config/GlobalConstant';



export const createPaymentlink = (
    token,
    bag,
    idUser,
    type,
    typeOfDelivery,
    deliveryAddress,
    phone,
    deliveryEntityId,
    typeOfPay,
    quantity,
    mp,
    history) => async dispatch => {
        const items = bag.map(item => {
            return {
                productId: item._id,
                quantity: parseInt(item.quantity)
            }
        })
        // history.push("payment/success")
        //return
        await fetch(`${urlApi}productorders/create`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                products: items,

                transmitterEntityId: idUser,
                ...(type == "user" || type == "re") && {
                    typeOfDelivery: typeOfDelivery,
                    ...(typeOfDelivery == 'home') && {
                        deliveryAddress: deliveryAddress,
                        deliveryPhone:phone
                    },
                    ...(typeOfDelivery == 'pickup') && {
                        deliveryEntityId: deliveryEntityId
                    }

                },
                ...(typeOfPay == "ecos") && {
                    isRedeem: true,
                    quantity: quantity
                },
                ...(typeOfPay == "combinacion") && {
                    isRedeem: true,
                    quantity: quantity
                },
            })
        })
            .then(response => response.json())
            .then(respJson => {
                if (respJson.success) {
                    if (respJson.data.orderType == 'purchase') {
                        mp.checkout({
                            preference: {
                                id: respJson.data.paymentReferenceId
                            },
                            autoOpen: true
                        })
                    }else{
                        history.push('Inventory/Inventory')
                    }

                    dispatch({
                        type: "CLEAR_BAG"
                    })
                }

                console.log(respJson);
            })
    }

export const createPaymentlinkPoints = (bag, idUser, mp, history) => async dispatch => {
    const items = bag.map(item => {
        return {
            packageId: item._id,
            quantity: parseInt(item.quantity)
        }
    })
    // history.push("payment/success")
    //return
    await fetch(`${urlApi}pointpackagesales/create`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${inMemoryJwt.getToken()}`
        },
        body: JSON.stringify({
            packages: items,
            buyerFranchiseId: idUser,
        })
    })
        .then(response => response.json())
        .then(respJson => {
            if (respJson.success) {
                mp.checkout({
                    preference: {
                        id: respJson.data.paymentReferenceId
                    },
                    autoOpen: true
                })
            }

            console.log(respJson);
        })
}