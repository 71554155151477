import React from 'react'
import style from './TitleImg.module.css'

const TitleImg = ({img, titleHighlight, title,height=30}) => {
  return (
    <div>
      <div className={style.title_text}>
        {img && <img className={style.img_icon} src={img} height={height} />}
        <span>
          <span className={style.title_text_highlight}>{titleHighlight}</span>
          {title}
        </span>
      </div>
    </div>
  )
}

export default TitleImg