const initialJobsState = {
    isLoadingJobs:false,
    JobsLists:[],
    isLoadingJob:false,
    job:{}
}
export default function (state = initialJobsState, action) {
    switch (action.type) {
        case 'LOADING_GET_JOBS_OFFERS':
            return { ...state, isLoadingJobs:true }
        case 'GET_JOBS_OFFERS':
            return { ...state, JobsLists: action.payload,isLoadingJobs:false }
        case 'LOADING_GET_OFFER_BY_ID':
            return { ...state, isLoadingJob:true }
        case 'GET_JOB_OFFER_BY_ID':
            return { ...state, job: action.payload,isLoadingJob:false }
        default:
            return state;
    }
}