import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { createPaymentlink } from '../../redux/actions/PasarelaActions'
import useMercadoPago from '../../utils/hooks/useMercadoPago'
import { reSendCode, sendCodeRegister, sendRegisterUser } from '../../redux/actions/RegisterUserActions'
import { Col, Container, Row } from 'react-bootstrap'
import iconLogo from '../../assets/img/icons/logo_final.svg'
import Button from '../../utils/Components/Button/Button'


export const VerifyCode = () => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const { userInfo, bag, ubication } = useSelector((state) => state.marketPlace)
  const mp = useMercadoPago()
  const location = useLocation()
  console.log('location: ', location);
  useEffect(() => {
    if (userInfo?._id) {
      dispatch(createPaymentlink(
        userInfo?.token.token,
        bag, userInfo?._id, 'user', 'home', {
        currentCountry: location.state.currentLocation,
        currentDepartment: location.state.currentDepartment,
        currentCity: location.state.currentCity,
        currentLocation: location.state.currentLocation,
        currentAddress: location.state.currentAddress,
      },
        location.state.deliveryPhone,
        null, 'pesos', 0, mp, history))
    }
    return () => {

    }
  }, [userInfo])


  const formik = useFormik({
    initialValues: {
      code: '',

    },
    onSubmit: (values) => {
      dispatch(sendCodeRegister(values.code, history))
    }
  })
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <img src={iconLogo} />
          </Col>
          <Col>
            <h4>Ingresa el código de verificación que enviamos a tu correo</h4>
            <input
              className='input-style'
              type='text'
              placeholder='Código'
              onChange={formik.handleChange}
              name='code'
              value={formik.values.code}
            />
            <div className='container-btn-register'>
              <Button type='submit' onClick={formik.handleSubmit} text='Enviar' />

              <p>Si no te llego el codigo prueba Reenviar: </p>
              <Button type='submit' onClick={() => (
                dispatch(reSendCode(location.state.user))
              )}
                text='Reenviar'
              />

            </div>

          </Col>
        </Row>
      </Container>

    </div>
  )
}
