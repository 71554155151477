

const initialState = {
    totalOil:0
}


export default function (state = initialState, action) {

    switch (action.type) { //que me llega la cadena type para el switch y el payload en este caso 
        case "GET_TOTAL_OIL":
            return { ...state, totalOil: action.payload }
        default:
            return state
    }
}