import React from 'react';
import { Carousel } from 'react-bootstrap';
import './style.css';
import image1 from '../../assets/img/Icarrousel_categoria0.svg';
import image2 from '../../assets/img/Icarrousel_categoria1.svg';
import image3 from '../../assets/img/Icarrousel_categoria2.svg';
import image4 from '../../assets/img/Icarrousel_categoria3.svg';
import image5 from '../../assets/img/Icarrousel_categoria4.svg';

const ICarousel = () => {
  const slides = [
    {
      image: image1,
      /*title: 'Presentando Biotu',
      subtitle: 'Submarca Ecoideas'*/
    },
    {
      image: image2,
     /* title: 'Slide 2 Title',
      subtitle: 'Slide 2 Subtitle'*/
    },
    {
      image: image3,
      /*title: 'Slide 3 Title',
      subtitle: 'Slide 3 Subtitle'*/
    },
    {
      image: image4,
      /*title: 'Slide 3 Title',
      subtitle: 'Slide 3 Subtitle'*/
    },{
      image: image5,
      /*title: 'Slide 3 Title',
      subtitle: 'Slide 3 Subtitle'*/
    },
  ];

  return (
    <div className="carousel-container">
      <Carousel>
        {slides.map((slide, index) => (
          <Carousel.Item key={index} interval={2000}>
            <img
              className="mx-auto d-block carousel-img-size"
              src={slide.image}
              alt={`Slide ${index + 1}`}
            />
            <Carousel.Caption>
              <h3>{slide.title}</h3>
              <p>{slide.subtitle}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default ICarousel;