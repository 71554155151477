import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import phoneicon from '../../assets/img/icons/phone.svg'
import ubicationicon from '../../assets/img/icons/ubication-icon.svg'
import { getEcotiendaById } from '../../redux/actions/EcotiendasActions'

export const EcoTiendaDetail = () => {
    const match = useParams()
    const { id } = match
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getEcotiendaById(id));
    }, []);
    const { ecotienda } = useSelector((state) => state.ecotienda)
    console.log('veamos que tar', ecotienda);
    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <img src={ecotienda.picture && ecotienda.picture.url && ecotienda.picture.url.large}>

                        </img>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{ecotienda.socialReason}</h4>
                    </Col>
                    <Row>
                    <Row>
                        <Col>
                            <div className='align-icons-eco'>
                                <img src={ubicationicon} className='icons-ecotiendas' />
                                <img src={phoneicon} className='icons-ecotiendas' />
                            </div>
                        </Col>
                        <Col>
                            <p className='uppercase'>{ecotienda.ubication.businessAddress}</p>
                            <p>{ecotienda.businessPhone}</p>
                        </Col>
                    </Row>
                    <Col>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d913.4940595522119!2d-76.58983717082322!3d2.455153299888185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e30039eb334bddf%3A0xf38ae99c8f6641b8!2sCl.%2026A%20Nte.%20%23431%2C%20Popay%C3%A1n%2C%20Cauca!5e1!3m2!1ses!2sco!4v1655928690013!5m2!1ses!2sco"
                            width={600}
                            height={450}
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />

                    </Col>
                    </Row>
                </Row>
            </Container>

        </div>
    )
}
