import React from 'react'
import './Input.css'

const Input = ({
    error='',
    name,
    value,
    center,
    theme = '',
    type = "text",
    placeholder = '',
    classNameContent = '',
    classNameInput = '',
    autoComplete,
    disabled = false,
    min = 0,
    max,
    onBlur = () => { },
    onFocus = () => { },
    onChange = () => { },
    onClick = () => { },
}) => {

    return (
        <div className={classNameContent}>
            <input
                className={`${classNameInput} input-style ${center ? 'center-input' : ''}`}
                type={type}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                disabled={disabled}
                placeholder={placeholder}
                autoComplete={autoComplete}
                min={min}
                max={max}
                onClick={onClick}
            />
            {error&&<div className={`text-error-input  ${center ? 'center-input' : ''}`}>{error}</div>}
        </div>
    )
}

export default Input