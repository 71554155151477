import React, { useEffect } from 'react'
import Section from '../Section/Section'
import { Button } from '../Button/Button'
import NCard from '../Card/NCard'
import imgExampleNew from '../../assets/img/example-new.svg'
import exampleNew from '../../assets/img/example-new.svg'
import { NCarousel } from '../Carousel/NCarousel'
import '../News/style.css'
import { NotiCard } from '../Card/NotiCard'
import { useDispatch, useSelector } from 'react-redux'
import { getAdsByType } from '../../redux/actions/BannerActions'
export const News = () => {
  const dispatch = useDispatch()
  const { adsNewLists } = useSelector((state) => state.adsList)
  useEffect(() => {
    dispatch(getAdsByType("new"))

    return () => {

    }
  }, [])
  return (
    <div>
      <Section  text2={' NOTICIAS DESTACADAS'} />
      <NCarousel slide1={imgExampleNew} />
      <Section text1={'OTRAS NOTICIAS'}  />
      <div className='notice_list'>
        {
          adsNewLists.map(notice =>
            <NotiCard img={notice.image ? notice.image?.url?.medium : "#"} alt='' className='img-card-work' link={notice.link} title={notice.name} texto={notice.description} />
          )
        }


      </div>
      {/*       <div className='btn-container space-bottom-news'>
        <Button texto={'ATRÁS'} />
      </div> */}
      <Section></Section>
    </div>
  )
}
export default News