import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Section from '../Section/Section'
import logo from '../../assets/img/icons/icon-logo.png'
import logotipo from './../../assets/img/ecoideas-logotipo.svg'
import imgAbout from '../../assets/img/img-about.svg'
import './style.css'
import { Button } from '../Button/Button'
export const OurHistory = () => {
  return (
    <div>
        <Section text2={'HISTORIA'}/>
        <Container>
            <Row>
                <Col>
                    <p>
                        Desde nuestros inicios, nos clasificamos como usuarios industriales y de servicios de la Zona Franca Permanente del Cauca, con la misión de producir y comercializar productos de aseo biodegradables, tanto de cuidado personal como del hogar.<br/>
                        <br/>
                        A principios del 2022, terminamos la construcción de nuestra planta de productos de higiene doméstica y productos absorbentes de higiene personal; Certificada con capacidad de producción por el INVIMA el 14 de marzo de 2022.<br/> Posteriormente, es adicionada nuestra planta de productos de higiene personal, y cosmética; Certificada con capacidad de producción por el INVIMA el 28 de febrero de 2023.<br/>
                        <br/> 
                        El compromiso ambiental de Ecoideas incluye sus actividades como gestora ambiental para el almacenamiento, tratamiento, gestión y aprovechamiento de residuos (ACUS) de la Corporación Autónoma Regional del Cauca (CRC), la Corporación Autónoma Regional del Valle (CVC) y el Departamento Administrativo de Gestión del Medio Ambiente (DAGMA)
                        consolidando sus prácticas comerciales sostenibles en octubre de 2023, Ecoideas reforma sus estatutos, adoptando la condición de empresa B.<br/>
                        <br/>
                        Esta decisión implicó modificaciones significativas en su objeto social, en las obligaciones de las directivas y la implementación de herramientas de gobernanza legal, en Ecoideas, cada paso es parte del camino en busca del objetivo de ser agentes del cambio social y ambiental.
                    </p>
                </Col>
                <Col className='d-flex justify-content-center align-items-center'>
                <div className={'icons-history'}>
                    <img src={logo} className={'style-logo'}/>
                    <img src={logotipo} className={'style-logotipo-h'} />
                </div>                    
                </Col>
            </Row>
        </Container>
        <img src={imgAbout}/>
    </div>
  )
}
