// ProfileCards.jsx
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'; // Asegúrate de importar el archivo CSS

import master1 from '../../assets/img/master1.png';
import master2 from '../../assets/img/master2.png';
import master3 from '../../assets/img/master3.png';
import master4 from '../../assets/img/master4.png';
import master5 from '../../assets/img/master5.png';
import master6 from '../../assets/img/master6.png';
import master7 from '../../assets/img/master7.png';
import master8 from '../../assets/img/master8.png';
import { Description } from '@material-ui/icons';

const profiles = [
  {
    name:  'Diana Carolina Arias',
    title2:'Ciudad: Candelaria',
    description: 'Dirección: Barrio Cañamiel',
    image: master1,
    title:'Contacto: 3203661551',
  },
  {
    name: 'Julian Andres Ararat ',
    title2:'Ciudad: Guachené',
    description: 'Dirección: Cabecera Municipal',
    image: master4,
    title:'Contacto: 3114088671',
  },
  {
    name: 'Luisa Hidalgo',
    title2:'Ciudad: Candelaria',
    description:'Dirección: Corregimiento San Joaquin',
    image: master3,
    title:'Contacto: 3161004630'
  },
  {
    name: 'Martha Cecilia Lucumi Mulato',
    title2:'Ciudad: Santiago de Cali',
    description:'Dirección: Barrio Alirio Mora Beltran',
    image: master2,
    title: 'Contacto: 3174257788'
  },
  {
    name: 'Lisandro Vásquez',
    title2:'Ciudad: Santiago de Cali',
    description: 'Dirección: Valle del Lili',
    image: master5,
    title:'Contacto: 3217604730'
  },
  {
    name: 'Johandra Isabel Albarran Bracho',
    title2:'Ciudad: Popayán',   
    description: 'Dirección: la Independencia',
    image: master6,
    title: 'Contacto: 3504056389'
  }
  ,
  {
    name: 'María del Rocío Bedoya Escudero',
    title2:'Ciudad: Palmira',   
    description: 'Dirección: Barrio Guallacanes del ingenio',
    image: master7,
    title: 'Contacto: 3128092476'
  }
  ,
  {
    name: 'Marisol Arias Rodriguez',
    title2:'Ciudad: Candelaria',   
    description: 'Dirección: La Aldea villagorgona',
    image: master8,
    title: 'Contacto: 3156711561'
  }

];

const EcoMastersCards = () => {
  return (
    <Row className="card-container">
      {profiles.map((profile, index) => (
        <Col key={index} sm={12} md={6} lg={4} className="mb-4">
          <Card className="card-item">
            <Card.Img variant="top" src={profile.image} alt={profile.name} className="card-img" />
            <Card.Body>
              <Card.Title>{profile.name}</Card.Title>
              
              <Card.Subtitle className="mb-2 text-muted" >  {profile.title2}</Card.Subtitle>
              <Card.Text>{profile.description}</Card.Text>
              <Card.Subtitle className="mb-2 text-muted">{profile.title}</Card.Subtitle>
          
              
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default EcoMastersCards;