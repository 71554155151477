import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { RegisterView } from '../../containers/Register/RegisterView'
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { sendRegisterUser } from '../../redux/actions/RegisterUserActions';
import { useLocation, useNavigate } from 'react-router-dom';

export const Register = () => {
    const dispatch = useDispatch()
    const history = useNavigate()
    const location = useLocation()
    console.log('ubicacion: ',location);
    useEffect(() => {
      if (!location.state) {
        history('/shoping-bag')   
      }
    
      return () => {
        
      }
    }, [])
    

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Es requerido"),
        email: Yup.string().required("Es requerido"),
        phone: Yup.string().required("Es requerido"),
        password: Yup.string().required("Es requerido"),
        confirmPassWord: Yup.string().required("Es requerido"),
        isAcceptTerms: Yup.boolean().required('Es requerido')

      });
const formik = useFormik({
    validationSchema,
    initialValues :{
        name:'',
        lastname:'',
        email:'',
        birthDate:new Date(),
        password:'',
        confirmPassWord:'',
        phone:'',
        isAcceptTerms:''
    },
    onSubmit:(values)=>{
        console.log(values);
        
        dispatch(sendRegisterUser(values,history,location))
    }
})
  return (
        <RegisterView
        formik = {formik}
        />
  )
}
