
import React, { useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getJobOfferById } from '../../redux/actions/JobsActions';
// import '../OfferJob/style.css'
import './style.css'

export const OfferJob = () => {
    const match = useParams()
    const { id } = match
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getJobOfferById(id));
    }, []);
    const { job } = useSelector((state) => state.jobsOffers)
    return (
        <div>
            <Container>
                <Row>
                    <Col className='card-job-style'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title><h4>{job.name}</h4></Card.Title>
                                <Card.Subtitle>{new Date(job.applicationDate).toLocaleDateString()}</Card.Subtitle>
                                <br></br>
                                <Card.Text>
                                    Descripción:
                                    {' ' + job.description}
                                </Card.Text>
                                <Card.Text>
                                    Ubicación:
                                    {' ' + job.country} - {job.state} - {job.city}
                                </Card.Text>
                                <Card.Text>
                                    Salario:
                                    {' ' + job.mimSalary} - {job.maxSalary}
                                </Card.Text>
                                <Card.Text>
                                    <h4>Contácto</h4>
                                    Empresa: {' ' + job.company}                                 
                                </Card.Text>
                                <Card.Text>
                                Correo: {' ' + job.email}
                                </Card.Text>
                                <Card.Text>
                                Contacto: {' ' + job.contact}
                                </Card.Text>
                                <Card.Text>
                                <a href={job?.offerpdf?.url.origin} target="_blank" rel="noopener noreferrer">Ver oferta</a>
                                </Card.Text>
                            </Card.Body>    
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default OfferJob
