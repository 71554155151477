import { combineReducers } from "redux"
import AdsReducer from "./AdsReducer"
import EcotiendasReducer from "./EcotiendasReducer"
import JobsReducer from "./JobsReducer"
import marketPlaceReducer from './MarketPlaceReducer'
import oilReducer from './oilReducer'
const appReducer = combineReducers({
    marketPlace: marketPlaceReducer,
    jobsOffers: JobsReducer,
    adsList:AdsReducer,
    ecotiendas:EcotiendasReducer,
    ecotienda:EcotiendasReducer,
    oil:oilReducer,
  })
  const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      return appReducer(undefined, action)
    }
  
    return appReducer(state, action)
  }
  export default rootReducer;