import inMemoryJWT from '../../auth/inMemoryJwt';
import { urlApi } from '../../config/GlobalConstant';


export const getLimitReports = (reportsLimit) => async dispatch => {
    dispatch({ //Esto se lo envía a combineReducer, el que está en el index
        type: 'IS_LOADING_GET_LIMIT_REPORTS'
    });
    await fetch(`${urlApi}adminparameters/get`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${inMemoryJWT.getToken()}`
            // 'Content-Type': 'application/x-www-form-urlencoded',

        }, // body data type must match "Content-Type" header    
    })
        .then(response => response.json())
        .then(respJson => {
            console.log(respJson)
            dispatch({ //Esto se lo envía a combineReducer, el que está en el index
                type: 'GET_LIMIT_REPORTS',
                payload: {
                    limitRejectedRequests: respJson.data.limitRejectedRequests,
                    limitAmountOfReports: respJson.data.limitAmountOfReports,
                    limitAmountOfInfractions: respJson.data.limitAmountOfInfractions,
                    shippingCost: respJson.data.shippingCost,
                    ecoPrice: respJson.data.ecoPrice
                }
            });

        })
        .catch(error => {
            console.log(error)
        })
}