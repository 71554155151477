import spanish from './es';

export const DEFAULT_LOCALE = 'es';

export const translations = {
  es: spanish,
};

export function Translate(message, locale = DEFAULT_LOCALE) {
  // We're actually asking for 'something' to be translated
  if (message) {
    // The translation exists AND the message exists in this translation
    if (translations[locale] && translations[locale][message]) {
      return translations[locale][message];
    }
    // Otherwise try in the default translation
    if (translations[DEFAULT_LOCALE] && translations[DEFAULT_LOCALE][message]) {
      return translations[DEFAULT_LOCALE][message];
    }
  }
  return "#"+message+"#";
}
