
import inMemoryJwt from '../../auth/inMemoryJwt';
import { urlApi } from '../../config/GlobalConstant';
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000
})

export const sendRegisterUser = (registerUser,history,location) => async dispatch => {
    console.log(registerUser)
    const formData = new FormData()
        formData.append('name',registerUser.name)
        formData.append('lastname',registerUser.lastname)
        formData.append('email',registerUser.email)
        formData.append('birthDate',registerUser.birthDate)
        formData.append('password',registerUser.password)
        formData.append('phone',registerUser.phone)
        formData.append('isAcceptTerms',registerUser.isAcceptTerms)
        formData.append('comesFromWebsite',true)

    await fetch(`${urlApi}static-routes/register`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
        },
        body: formData// body data type must match "Content-Type" header      
    })
        .then(response => response.json())
        .then(respJson => {
            console.log(respJson)
            if (respJson.success === true) {
                history('/verify-code',{state:{...location.state,user:registerUser}})
                
            }
            if (respJson.success === false) {

            }

        })
        .catch(error => {

        })


}
export const sendCodeRegister = (code,history) => async dispatch => {

    await fetch(`${urlApi}static-routes/verify-code`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            code : code
        })  
    })
        .then(response => response.json())
        .then(respJson => {

            if (respJson.success === true) {
                console.log('respuesta: ',respJson.data);
                dispatch({
                    type:'GET_USER_INFO',
                    payload:respJson.data,
                
                })
            }
            if (respJson.success === false) {

            }

        })
        .catch(error => {

        })


}
export const reSendCode = (registerUser) => async dispatch => {
    console.log(registerUser)
    const formData = new FormData()
        formData.append('name',registerUser.name)
        formData.append('lastname',registerUser.lastname)
        formData.append('email',registerUser.email)
        formData.append('birthDate',registerUser.birthDate)
        formData.append('password',registerUser.password)
        formData.append('phone',registerUser.phone)
        formData.append('isAcceptTerms',registerUser.isAcceptTerms)
        formData.append('comesFromWebsite',true)

    await fetch(`${urlApi}static-routes/register`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
        },
        body: formData// body data type must match "Content-Type" header      
    })
        .then(response => response.json())
        .then(respJson => {
            console.log(respJson)
            if (respJson.success === true) {
               Toast.fire({title:'Se reenvió el código'})
                
            }
            if (respJson.success === false) {
                if (respJson.error.code == 'MSJ006') {
                    Toast.fire({icon:'error' ,title: respJson.error.msg})
                }else{
                    Toast.fire({icon:'error' ,title:'Ocurrio un error,intente de nuevo!'})
                }
            }

        })
        .catch(error => {

        })


}