import inMemoryJwt from '../../auth/inMemoryJwt';
import { urlApi } from "../../config/GlobalConstant";
import Swal from 'sweetalert2';
const Toast = Swal.mixin({
    toast: true,

    showConfirmButton: false,
    timer: 2000
})

export const getProductsEcotienda = (id) => async dispatch => {
    await fetch(`${urlApi}static-routes/ecoideaspage/maininventories/get-consolidate-products`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
          
            // 'Content-Type': 'application/x-www-form-urlencoded',

        },
        body: JSON.stringify({
            franchiseId:id,
            limit: 10,
            skip: 0
        })
    })
        .then(response => response.json())
        .then(respJson => {
            console.log('respuesta : ',respJson);
            dispatch({type:'GET_PRODUCTS_ECOTIENDA',payload:respJson.data})
        })
}


export const addToBagPoints = (item) => async dispatch => {
    Toast.fire({
        text: "Producto añadido"
    })
    dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
        type: 'ADD_TO_POINTS_BAG',
        payload: item
    });
}
export const removeItemPoints = (item) => async dispatch => {
    dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
        type: 'REMOVE_ITEM_POINTS_BAG',
        payload: item
    });
}
export const changeQuantityPoints = (item, quantity) => async dispatch => {
    dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
        type: 'CHANGE_QUANTITY_POINTS_BAG',
        payload: { item, quantity: parseInt(quantity) }
    });
}



export const addToBag = (item) => async dispatch => {
    Toast.fire({
        text: "Producto añadido"
    })
    dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
        type: 'ADD_TO_BAG',
        payload: item
    });
}
export const removeItem = (item) => async dispatch => {
    dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
        type: 'REMOVE_ITEM',
        payload: item
    });
}
export const changeQuantity = (item, quantity) => async dispatch => {
    dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
        type: 'CHANGE_QUANTITY',
        payload: { item, quantity: parseInt(quantity) }
    });
}

export const getQr = (id, token) => async dispatch => {//payment/get-collectorId
    await fetch(`${urlApi}payment/get-preferenceId`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
            // 'Content-Type': 'application/x-www-form-urlencoded',

        }, // body data type must match "Content-Type" header    
        body: JSON.stringify({
            preference_id: id
        })
    })
        .then(response => response.json())
        .then(respJson => {
            dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
                type: 'PAY_SUCCESS',
                payload: respJson.data.QRCode
            });

        })
        .catch(error => {
            console.log(error)
        })
}

export const getDisscount = (rol) => async dispatch => {
    await fetch(`${urlApi}disscount/get-rol`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${inMemoryJwt.getToken()}`
        },
        body: JSON.stringify({
            rol
        })
    })
        .then(response => response.json())
        .then(respJson => {
            console.log(respJson);
            if (respJson.success) {
                dispatch({
                    type: "ADD_DISSCOUNT",
                    payload: {
                        value: respJson.data.value,
                        to: respJson.data.to
                    }
                })
            }
            console.log(respJson);
        })
}
export const cleartDisscount = () => dispatch => {
    dispatch({
        type: "CLEAR_DISSCOUNT"
    })
}

export const getByBuyer = (buyerId) => async dispatch => {
    await fetch(`${urlApi}productorders/get-by-buyer`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${inMemoryJwt.getToken()}`
            // 'Content-Type': 'application/x-www-form-urlencoded',

        }, // body data type must match "Content-Type" header    
        body: JSON.stringify({ buyerId })
    })
        .then(response => response.json())
        .then(respJson => {
            dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
                type: 'GET_PURCHASES',
                payload: respJson.data
            });
        })
}

export const getBatchesUser = (productOrderId, productId) => async dispatch => {
    await fetch(`${urlApi}deliveryproductbatches/get-batches-disabled`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${inMemoryJwt.getToken()}`
            // 'Content-Type': 'application/x-www-form-urlencoded',

        }, // body data type must match "Content-Type" header    
        body: JSON.stringify({ productOrderId, productId, entityId: localStorage.getItem('idUser') })
    })
        .then(response => response.json())
        .then(respJson => {
            dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
                type: 'GET_BATCHES_USER',
                payload: {
                    batches: respJson.data,
                    id: productId
                }
            });
        })
}

export const clearBatches = () => async dispatch => {
    dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
        type: 'GET_BATCHES_USER',
        payload: {
            batches: [],
            id: null
        }
    });
}

export const getBatchesGeneral = (productOrderId, productId, entityId) => async dispatch => {
    await fetch(`${urlApi}generalproductbatches/get-batches-disabled`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${inMemoryJwt.getToken()}`
            // 'Content-Type': 'application/x-www-form-urlencoded',

        }, // body data type must match "Content-Type" header    
        body: JSON.stringify({ productOrderId, productId, entityId })
    })
        .then(response => response.json())
        .then(respJson => {
            dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
                type: 'GET_BATCHES_USER',
                payload: {
                    batches: respJson.data,
                    id: productId
                }
            });
        })
}

export const getBatchesMain = (productOrderId, productId, entityId) => async dispatch => {
    await fetch(`${urlApi}mainproductbatches/get-batches-disabled`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${inMemoryJwt.getToken()}`
            // 'Content-Type': 'application/x-www-form-urlencoded',

        }, // body data type must match "Content-Type" header    
        body: JSON.stringify({ productOrderId, productId, entityId })
    })
        .then(response => response.json())
        .then(respJson => {
            dispatch({ //Esto se lo envía a combineReducer, el que está en el index 61b234836a1bb35e7dd4bd8d
                type: 'GET_BATCHES_USER',
                payload: {
                    batches: respJson.data,
                    id: productId
                }
            });
        })
}


export const getReasonsForDeletion = () => async dispatch => {
    await fetch(`${urlApi}reasonsfordeletion/get-all`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${inMemoryJwt.getToken()}`
            // 'Content-Type': 'application/x-www-form-urlencoded',

        }
    })
        .then(response => response.json())
        .then(respJson => {
            if (respJson.success) {
                dispatch({
                    type: 'GET_REASONS',
                    payload: respJson.data
                })
            } else {
                dispatch({
                    type: 'GET_REASONS',
                    payload: []
                })
            }
        })
}
//productdevolutions/create

export const createDevolition = (value, history) => async dispatch => {
    await fetch(`${urlApi}productdevolutions/create`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${inMemoryJwt.getToken()}`
            // 'Content-Type': 'application/x-www-form-urlencoded',

        }, body: JSON.stringify(value)
    })
        .then(response => response.json())
        .then(respJson => {
            if (respJson.success) {
                history.push('/MarketPlace/Purchases')
            } else {

            }
        })
}

export const seeplacesPickup = () => async dispatch => {
    dispatch({ //Esto se lo envía a combineReducer, el que está en el index
        type: "IS_LOADING_placesPickup"
    });
    await fetch(`${urlApi}warehouses/get-franchises-and-warehouses`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${inMemoryJwt.getToken()}`
            // 'Content-Type': 'application/x-www-form-urlencoded',

        }, // body data type must match "Content-Type" header    
    })
        .then(response => response.json())
        .then(respJson => {
            dispatch({ //Esto se lo envía a combineReducer, el que está en el index
                type: "LIST_placesPickup",
                payload: respJson.data
            });

        })
        .catch(error => {
            console.log(error)
        })

}


export const getEcopointsRedeem = () => async dispatch => {
    dispatch({ //Esto se lo envía a combineReducer, el que está en el index
        type: "IS_LOADING_placesPickup"
    });
    await fetch(`${urlApi}entityecopoints/get-ecopoints-redeem`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${inMemoryJwt.getToken()}`
            // 'Content-Type': 'application/x-www-form-urlencoded',

        }, // body data type must match "Content-Type" header,
        body:JSON.stringify({
            userId:localStorage.getItem('idUser')
        })
    })
        .then(response => response.json())
        .then(respJson => {
            dispatch({ //Esto se lo envía a combineReducer, el que está en el index
                type: "POINTS_TO_REDEEM",
                payload: respJson.data
            });

        })
        .catch(error => {
            console.log(error)
        })

}

export const getEcopointsBuy = (_idEc) => async dispatch => {
    await fetch(`${urlApi}pointbanks/get-by-entity`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //mode: 'cors', // no-cors, *cors, same-origin
        // withCredentials: true,
        //credentials: 'include',
        headers: {
            'Authorization': `bearer ${inMemoryJwt.getToken()}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            entityId: _idEc
        })
    })
        .then(response => response.json())
        .then(respJson => {
          
            if (respJson.success) {
                dispatch({
                    type:"POINTS_BUY",
                    payload:respJson.data[0]?.availablePoints
                })
            //respJson.data[0]?.availablePoints
            } else {
                dispatch({
                    type:"POINTS_BUY",
                    payload:0
                })
            }
        })
}

export const sendProductsToPay = (adress,history,country,city,location,state,phone) => dispatch => {
    dispatch({
        type:'SEND_TO_PAY',
        payload:{
            adress,
            history,
            country,
            city,
            location,
            state,
            phone,
        }
})
    history('/create-user',{state:{
            currentAddress:adress,
            currentCountry:country,
            currentCity:city,
            currentLocation:location,
            currentDepartment:state,
            deliveryPhone:phone,
    }})
}